<template>
	<table :class="size">
		<colgroup v-if="size == 'small'">
			<col style="width: 70px" />
			<col style="width: 325px; text-align: left" />
			<col style="width: 114px" />
			<col style="width: 60px" />
			<col style="width: 114px" />
			<col style="width: 106px; text-align: center" />
		</colgroup>
		<colgroup v-else>
			<col style="width: 100px" />
			<col style="width: 402px; text-align: left" />
			<col style="width: 228px" />
			<col style="width: 60px" />
			<col style="width: 180px; text-align: center" />
			<col style="width: 228px" />


		
		</colgroup>
		<thead>
			<tr>
				<th></th>
				<th style="text-align: right; padding-right: 50px">{{ $t('streamer') }}</th>
				<th style="text-align: right; padding-right: 50px"> {{ $t('guestTeam') }}</th>
				<th></th>
				<th style="text-align: left; padding-left: 50px">{{ $t('homeTeam') }}</th>
				<th>{{ $t('contests_name') }}</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="match in lists" :key="match.tournament_id">
				<td>
					<a href="javascript:;" :class="['appointment', match.is_reservation == 1 ? '' : 'active']" @click="$emit('appointment', match)">
						{{ match.is_reservation == 1 ? $t('booked') :  $t('reservation') }}
					</a>
				</td>
				<td>
					<div class="anchors" v-if="match.anchor.length === 0">
						<div class="anchor"><img src="../assets/img/undetermined.png" /></div>
					</div>
					<div class="anchors" v-else>
						<a
							class="anchor"
							v-for="anchor in match.anchor || []"
							:key="anchor.uid"
							:href="`/detail/${match.tournament_type}/${match.tournament_id}/${anchor.member_id}`"
							target="_blank"
						>
							<img :src="anchor.face || '0'" @error="onAvatarError" />
							<span :title="anchor.username">{{ anchor.username }}</span>
						</a>
					</div>
				</td>
				<td>
					<div class="team">
						<img :src="match.away_logo || '0'" @error="onTeamLogoError($event, match.tournament_type)" />
						<span>{{ match.away_team_zh }}</span>
					</div>
				</td>
				<td v-if="match.state == 0">{{ $t('notStarted') }}</td>
				<td v-else><span class="inprogress">{{ $t('living') }}</span></td>
				<td>
					<div class="team">
						<span  style="text-align: left;">{{ match.home_team_zh }}</span>
						<img :src="match.home_logo || '0'" @error="onTeamLogoError($event, match.tournament_type)" />
					</div>
				</td>


				<td>
					<span class="league">
						<span class="league-type">{{ tournamentType(match.tournament_type) }}</span>
						<span class="league-name">{{ match.league_name_zh}}</span>
					</span>
					<span class="time">{{ (match.match_time_int  || match.start_time_int) | dateFormat('HH:mm') }}</span>
				</td>




			</tr>
		</tbody>
	</table>
</template>

<script>
import dayjs from 'dayjs';
export default {
	name: 'wsScheduleTable',
	props: {
		size: {
			type: String,
			default: ''
		},
		lists: {
			type: Array,
			default() {
				return [];
			}
		}
	},
	data() {
		return {};
	},
	filters: {
		tournamentType(val) {
			return ['', '足球', '篮球', '其他', '网球', '排球', '台球', '羽毛球', '电竞'][val]
		},
		timestampToDate(value, format = 'YYYY-MM-DD HH:mm:ss') {
			if (!value) return '';
			// 检查时间戳长度，如果是10位，则转换为毫秒级别
			const timestamp = value.toString().length === 10 ? value * 1000 : value;
			return dayjs(timestamp).format(format);
		},
		strLength(val){
			console.log(val);
			if(val && val.length > 7){
			return  val.slice(0,7)+'......'
			}else {
				return val
			}
		}
	},
    methods: {
        tournamentType(val){
            return ['', this.$t('football'), this.$t('basketball'), this.$t('rest'), this.$t('tennis'), this.$t('volleyball'), this.$t('billiards'), this.$t('badminton'), this.$t('e_sports')][val]
        }
    },
};
</script>

<style lang="less">

.schedule {
	background-color: #ffffff;
	&-header {
		position: relative;
		height: 72px;
		padding-top: 14px;
		display: flex;
		flex-direction: row;
		align-items: center;
		box-sizing: border-box;
		color: var(--primary-color);
		justify-content: flex-end;
		font-size: 20px;
		line-height: 1;
		&::before {
			content: '';
			height: 20px;
			border-left: var(--primary-color) 4px solid;
			margin-right: 10px;
		}
	}

	&-body {
		table {
			width: 100%;
			border: 1px solid #e4e4e4;
			white-space: nowrap;
			th {
				text-align: center;
				height: 50px;
				background: var(--primary-color);
				font-size: 16px;
				color: #ffffff;
			}
			td {
				text-align: center;
				height: 72px;
				border-bottom: 1px solid #eef3f8;
			}

			&.small {
				.anchors .anchor {
					width: 65px;
				}
				.league-type {
					display: none;
				}
				.team {
					max-width: 150px;
				}
				.team img {
					margin: 0 2px;
				}
			}
		}
		.league {
			color: #737d92;
			font-size: 14px;
			display: flex;
			justify-content: center;
			&-name {
				width: 100px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				direction: rtl;
			}
			&-type {
				margin-right: 4px;
			}
		}
		.time {
			font-size: 20px;
			font-weight: 500;
			color: #333333;
		}

		.inprogress {
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			font-weight: 500;
			color: #ed4b65;
			&::before {
				content: '';
				width: 8px;
				height: 8px;
				background: #ed4b65;
				border-radius: 50%;
				margin-right: 4px;
			}
		}

		.anchors {
			text-align: right;
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			padding:10px 0;
			max-width: 350px;
			overflow:auto;
			.anchor {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				font-size: 12px;
				color: #737d92;
				width: 80px;
				img {
					width: 36px;
					height: 36px;
					border-radius: 50%;
					display: block;
					margin-bottom: 4px;
				}
				span {
					width: 74px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					text-align: center;
				}
			}
		}
		.team {
			text-align: left;
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			max-width: 228px;
			span {
				flex: 1;
				min-width: 0;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: 16px;
				font-weight: 500;
				text-align: right;
				direction: rtl;
			}
			img {
				display: block;
				width: 38px;
				height: 38px;
				border-radius: 50%;
				margin: 0 8px;
			}
		}
		.appointment {
			display: block;
			padding: 0 8px;
			color: #cacaca;
			border: 2px solid #cacaca;
			border-radius: 16px;
			line-height: 28px;
			&.active {
				line-height: 32px;
				background: var(--active-bgcolor);
				border: 0;
				color: #ffffff;
			}
		}
	}

	.empty {
		height: 72px;
		display: flex;
		align-items: center;
		flex-direction: row;
		justify-content: flex-end;
		padding-left: 60px;
		font-size: 20px;
		color: #4f5a70;
	}
}
</style>
